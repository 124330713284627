import { useState } from "react";
import styled, { x } from "@xstyled/styled-components";

import { FilterToggle, UpsellFilterToggle } from "./components/FilterToggle";
import { ShortFilterOptions } from "./components/graph-helpers";

import {
  Button,
  Card,
  Heading,
  HorizontalChart,
  Spacing,
  Text,
  UpsellHorizontalChart,
} from "@otta/design";
import { palette } from "@otta/design-tokens";
import { JobCandidatePoolStatistics } from "@hire/schema";
import { Icon } from "@otta/icons";

const TitleSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Container = styled(Card)`
  background-color: white;
  border: 1px solid ${palette.grayscale.shade200};
  padding: xl;

  width: 100%;
`;

export function CandidatePool({
  data,
  isProCompany,
}: {
  data?: JobCandidatePoolStatistics;
  isProCompany: boolean;
}) {
  const [currentFilter, setCurrentFilter] =
    useState<ShortFilterOptions>("genders");

  return (
    <Spacing>
      <TitleSection>
        <div>
          <Text bold size={2}>
            Candidate pool
          </Text>
          <Text>Matched candidates active in the last 12 weeks</Text>
        </div>
        {isProCompany && (
          <FilterToggle
            currentFilter={currentFilter}
            changeFilter={filter =>
              setCurrentFilter(filter === "all" ? "genders" : filter)
            }
            section="candidate-pool"
            displayAll={false}
          />
        )}
        {!isProCompany && <UpsellFilterToggle displayAll={false} />}
      </TitleSection>

      <Container>
        <Spacing>
          <Heading bold size={3}>
            {data?.total.toLocaleString()} candidates
          </Heading>
          {isProCompany && data && data[currentFilter] && (
            <HorizontalChart data={data[currentFilter] ?? []} />
          )}
          {!isProCompany && <UpsellHorizontalChart />}
        </Spacing>
      </Container>

      {!isProCompany && (
        <Container>
          <x.div display="flex" gap="xl" alignItems="flex-start">
            <x.img
              w={363}
              maxW="33%"
              src="https://static.otta.com/hire/analytics_upsell.png"
            />
            <x.div
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-start"
              gap="lg"
            >
              <x.div display="flex" flexDirection="column" gap="lg">
                <Text size={1} bold>
                  Unlock advanced analytics
                </Text>
                <x.ul
                  display="flex"
                  flexDirection="column"
                  gap="lg"
                  listStyleType="none"
                >
                  <x.li display="flex" alignItems="center" gap="sm">
                    <Icon icon="checkmark" />
                    <Text>
                      Filter your candidate pool and inbound performance by
                      gender, ethnicity and seniority
                    </Text>
                  </x.li>
                  <x.li display="flex" alignItems="center" gap="sm">
                    <Icon icon="checkmark" />
                    <Text>Unlock benchmarks for your inbound performance</Text>
                  </x.li>
                </x.ul>
              </x.div>
              <Button
                as="a"
                level="primary"
                href="https://meetings.hubspot.com/yoyin/qualification-15-mins-500-?utm_source=hire_app&utm_medium=analytics&utm_campaign=upsell"
                target="_blank"
                rel="noopener noreferrer"
                data-analytics-id="analytics-upsell-upgrade-plan"
              >
                Upgrade your plan
              </Button>
            </x.div>
          </x.div>
        </Container>
      )}
    </Spacing>
  );
}
