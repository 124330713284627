import { JobFormFields } from "./types";
import {
  compileLocationPreferences,
  mapQueryResultToSelectStructure,
  mapSelectionToMutationInput,
} from "./util";

import { CompanyJobInput, Currency, JobFragment } from "@hire/schema";
import { Year } from "@hire/components/sliders/ExperienceRangeSlider";

export const transformIn = (data?: JobFragment | null): JobFormFields => {
  return {
    title: data?.title ?? "",
    subtitle: data?.subtitle ?? "",
    internship: data?.internship ?? false,
    externalId: data?.externalId ?? undefined,
    experienceRange: [
      (data?.minYearsExperienceRequired ?? 1) as Year,
      (data?.maxYearsExperienceRequired ?? 3) as Year,
    ],
    salaryRange: {
      minAmount: data?.salaryRange?.minAmount
        ? parseInt(data.salaryRange.minAmount)
        : null,
      maxAmount: data?.salaryRange?.maxAmount
        ? parseInt(data.salaryRange.maxAmount)
        : null,
      currency: data?.salaryRange?.currency ?? Currency.Gbp,
    },
    oteRange: data?.oteSalaryRange?.minAmount
      ? {
          minAmount: data?.oteSalaryRange?.minAmount
            ? parseInt(data.oteSalaryRange.minAmount)
            : null,
          maxAmount: data?.oteSalaryRange?.maxAmount
            ? parseInt(data.oteSalaryRange.maxAmount)
            : null,
          currency: data?.oteSalaryRange?.currency,
        }
      : undefined,
    hideSalary: data?.hideSalary ?? null,
    salaryVisibleToIds: [],
    otherCompensation: data?.otherCompensationInformation ?? "",
    jobFunction: data?.jobFunction?.id ?? "",
    jobSubFunction: data?.subFunction?.id ?? "",
    technologiesUsed: data?.technologiesUsed
      ? data?.technologiesUsed.map(mapQueryResultToSelectStructure("value"))
      : [],
    requirements: [
      ...(data?.requirements?.filter(r => !r.desirable) ?? []),
    ].map(({ value, order, desirable }, index) => ({
      value,
      desirable,
      order: order ?? index,
    })),
    desirableRequirements: [
      ...(data?.requirements?.filter(r => r.desirable) ?? []),
    ].map(({ value, order, desirable }, index) => ({
      value,
      desirable,
      order: order ?? index,
    })),
    involvesBullets: [...(data?.involvesBullets ?? [])].map(
      (bullet, index) => ({
        value: bullet.value,
        order: bullet.order ?? index,
      })
    ),
    applicationProcessBullets: data?.applicationProcessBullets
      ? data?.applicationProcessBullets.map((bullet, index) => ({
          value: bullet.value,
          order: bullet.order ?? index,
        }))
      : [],
    requiredOfficeDaysRange: {
      minDaysInOffice:
        data?.minDaysInOffice || data?.minDaysInOffice === 0
          ? data.minDaysInOffice
          : undefined,
      maxDaysInOffice: data?.maxDaysInOffice ? data.maxDaysInOffice : undefined,
    },
    officeLocation: data?.officeLocation ?? "",
    locationRestrictions: data?.locationRestrictions ?? "",
    locationInformation: data?.locationInformation ?? "",
    locationPreferences: data?.locationPreferences
      ? data.locationPreferences.map(({ location }) => location)
      : [],
    languageRequirements: data?.languageRequirements
      ? data.languageRequirements.map(mapQueryResultToSelectStructure("value"))
      : [],
    companyTeamId: data?.team?.id ?? null,
  };
};

/**
 * removes blank items but also re-indexes to avoid holes in the order for tidiness
 */
function omitBlankValues<A extends { order: number; value?: string }>(
  items: readonly A[]
): (A & { value: string })[] {
  return [...items]
    .sort((a, b) => a.order - b.order)
    .filter(({ value }) => !!value)
    .map((item, order) => ({ ...item, order })) as (A & { value: string })[];
}

export const transformOut = (data: JobFormFields): CompanyJobInput => {
  const formattedData = {
    internship: data.internship,
    otherCompensationInformation: data.otherCompensation,
    locationInformation: data.locationInformation,
    locationPreferences: compileLocationPreferences(data.locationPreferences),
    editedTitle: data.title,
    subtitle: data.subtitle ?? null,
    minYearsExperienceRequired: data.internship
      ? null
      : data.experienceRange[0],
    maxYearsExperienceRequired: data.internship
      ? null
      : data.experienceRange[1],
    minDaysInOffice: data.requiredOfficeDaysRange.minDaysInOffice ?? null,
    maxDaysInOffice: data.requiredOfficeDaysRange.maxDaysInOffice ?? null,
    functionId: data.jobFunction,
    subFunctionId: data.jobSubFunction,
    technologiesUsed: data.technologiesUsed?.map(mapSelectionToMutationInput),
    languageRequirements: data.languageRequirements?.map(
      mapSelectionToMutationInput
    ),
    requirements: omitBlankValues([
      ...data.requirements,
      ...data.desirableRequirements,
    ]).map(item => ({
      ...item,
      desirable: !!item.desirable,
    })),
    involvesBullets: omitBlankValues(data.involvesBullets),
    applicationProcessBullets: omitBlankValues(data.applicationProcessBullets),
    salaryRange: data.salaryRange.minAmount
      ? {
          minAmount: `${data.salaryRange.minAmount}`,
          maxAmount: `${data.salaryRange.maxAmount}`,
          currency: data.salaryRange.currency,
        }
      : null,
    oteSalaryRange: data.oteRange?.minAmount
      ? {
          minAmount: `${data.oteRange.minAmount}`,
          maxAmount: `${data.oteRange.maxAmount}`,
          currency: data.oteRange.currency,
        }
      : null,
    officeLocation: data.officeLocation,
    locationRestrictions: data.locationRestrictions,
    companyTeamId: data.companyTeamId,
  };

  const outgoingData =
    data.hideSalary !== null
      ? { ...formattedData, hideSalary: data.hideSalary }
      : formattedData;

  return outgoingData;
};
