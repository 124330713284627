import { QueryHookOptions, useQuery } from "@apollo/client";
import { useMemo, useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import {
  SourcingJobDocument,
  PersonalisedCandidatesPreferences,
  Currency,
  SourcingJobQuery,
  LastActive,
} from "@hire/schema";

const LOCAL_STORAGE_KEY = `sourcing-preferences-`;

export function useSourcingPreferences(
  jobId: string,
  options?: QueryHookOptions
): {
  preferences: PersonalisedCandidatesPreferences | undefined;
  setPreferences: (prefs: PersonalisedCandidatesPreferences) => void;
  loading: boolean;
  job?: SourcingJobQuery["companyJob"];
} {
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: sourcingJobData, loading: sourcingJobLoading } = useQuery(
    SourcingJobDocument,
    {
      variables: {
        jobId: jobId,
      },
      ...options,
    }
  );

  const job = sourcingJobData?.companyJob;

  const preferences = useMemo(():
    | PersonalisedCandidatesPreferences
    | undefined => {
    const q = searchParams.get("q");

    if (q) {
      const parsed = JSON.parse(window.atob(q));

      return parsed;
    }

    const stored = localStorage.getItem(`${LOCAL_STORAGE_KEY}${jobId}`);
    if (stored) {
      return JSON.parse(stored);
    }

    if (!job) {
      return undefined;
    }

    return {
      experienceRange: {
        minYearsExperienceRequired: job.minYearsExperienceRequired ?? 0,
        maxYearsExperienceRequired: job.maxYearsExperienceRequired ?? 8,
      },
      maxSalary: job.oteSalaryRange
        ? {
            amount: job.oteSalaryRange.maxAmount ?? "80000",
            currency: job.oteSalaryRange.currency ?? Currency.Gbp,
          }
        : {
            amount: job.salaryRange?.maxAmount ?? "80000",
            currency: job.salaryRange?.currency ?? Currency.Gbp,
          },
      jobSubFunctionIds: job.subFunction?.id ? [job.subFunction.id] : [],
      lastActive: LastActive.TwelveWeeksAgo,
    };
  }, [searchParams, job, jobId]);

  const setPreferences = useCallback(
    (newPreferences: PersonalisedCandidatesPreferences) => {
      const newParams = new URLSearchParams(searchParams);

      newParams.set("q", window.btoa(JSON.stringify(newPreferences)));
      setSearchParams(newParams);

      localStorage.setItem(
        `${LOCAL_STORAGE_KEY}${jobId}`,
        JSON.stringify(newPreferences)
      );
    },
    [searchParams, setSearchParams, jobId]
  );

  return {
    loading: sourcingJobLoading,
    preferences,
    setPreferences,
    job,
  };
}
