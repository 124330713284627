import styled from "@xstyled/styled-components";
import { useMemo, useState } from "react";
import { useMedia } from "react-use";

import { Statistic } from "./components/CardStatistics";
import { InboundPerformance } from "./types";
import { BarGraph } from "./components/BarGraph";
import { StackedBarGraph } from "./components/StackedBarGraph";
import { FilterOptions } from "./components/graph-helpers";
import { FilterToggle, UpsellFilterToggle } from "./components/FilterToggle";
import { MissingDataPlaceholder } from "./components/MissingDataPlaceholder";
import { CandidatesModal } from "./components/CandidatesModal";

import { Spacing, Text } from "@otta/design";
import { JobInboundPerformanceSubsetStatistics } from "@hire/schema";
import { ANALYTICS_DESKTOP_BREAKPOINT } from "@hire/components/layout/constants";

const TitleSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

// The layout is quite complex because we go from a grid to a flex layout depending on the screen size
// To do so, we're using a subgrid to ensure the stats are aligned on desktop
// and we're using flex on tablet to prevent overflow
// The HTML looks like:
//  <div class="container">
//    <div class="row">
//     <div class="stat" />
//     <div class="stat" />
//     <div class="stat" />
//     <div class="stat" />
//    </div>
//    <div class="row">
//     <div class="stat" />
//     <div class="graph" />
//    </div>
//  </div>
// And the grid:
// ┌────┬────┬────┬────┐
// │    │    │    │    │
// ├────┼────┴────┴────┤
// │    │              │
// └────┴──────────────┘
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, auto);
  row-gap: lg;

  @media (min-width: ${ANALYTICS_DESKTOP_BREAKPOINT}px) {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 40;
  }
`;

const Row = styled.div`
  display: flex;
  column-gap: 40;

  /**
   * Flex children have min-width/height at auto by default
   * We don't want them to overflow so we set them to 0
   * We can't use flex-shrink because we actually want them to shrink
   */
  > * {
    min-width: 0;
    flex-grow: 1;
  }

  @media (min-width: ${ANALYTICS_DESKTOP_BREAKPOINT}px) {
    grid-column: span 4;
    display: grid;
    grid-template-columns: subgrid;
  }
`;

const AverageTimeStatistic = styled.div`
  display: contents;
  flex-shrink: 0;
  flex-grow: 0;
`;

const GraphWrapper = styled.div`
  flex-grow: 1;
  @media (min-width: ${ANALYTICS_DESKTOP_BREAKPOINT}px) {
    grid-column: span 3;
  }
`;

export function InboundStatistics({
  data,
  isProCompany,
}: {
  data?: InboundPerformance | null;
  isProCompany: boolean;
}) {
  const [currentFilter, setCurrentFilter] = useState<FilterOptions>("all");

  const isDesktop = useMedia(`(min-width: ${ANALYTICS_DESKTOP_BREAKPOINT}px)`);

  const graphDetails = useMemo(() => {
    if (!data) return [];

    return [
      {
        title: "Saved job",
        value: data.totals.saved.total,
      },
      {
        title: "Clicked 'Apply'",
        value: data.totals.clickedApply.total,
      },
      {
        title: "Applied",
        value: data.totals.applied.total,
      },
    ];
  }, [data]);

  const filteredGraphDetails = useMemo(() => {
    const filteredData =
      currentFilter === "all"
        ? null
        : (data?.[currentFilter] as JobInboundPerformanceSubsetStatistics);

    return [
      {
        title: "Viewed",
        values: filteredData?.viewed ?? [],
      },
      {
        title: "Saved job",
        values: filteredData?.saved ?? [],
      },
      {
        title: "Clicked 'Apply'",
        values: filteredData?.clickedApply ?? [],
      },
      {
        title: "Applied",
        values: filteredData?.applied ?? [],
      },
    ];
  }, [data, currentFilter]);

  const [hoveredItem, setHoveredItem] = useState<string>();

  const hasData = data && data.totals.viewed.total > 0;

  const canFilter = Boolean(
    isProCompany &&
      hasData &&
      data.ethnicities &&
      data.experienceLevels &&
      data.genders
  );

  return (
    <Spacing size={2}>
      <TitleSection>
        <Text bold size={2}>
          Inbound performance
        </Text>
        {canFilter && (
          <FilterToggle
            currentFilter={currentFilter}
            changeFilter={filter => setCurrentFilter(filter)}
            section="inbound-performance"
          />
        )}
        {!isProCompany && <UpsellFilterToggle />}
      </TitleSection>
      {hasData && (
        <Container>
          <Row>
            <Statistic
              title="Viewed"
              value={data.totals.viewed.total}
              filterData={filteredGraphDetails[0].values}
            />
            <Statistic
              title="Saved"
              value={data.totals.saved.total}
              benchmark={data.totals.saved.benchmark ?? undefined}
              conversion={data.totals.saved.conversion ?? undefined}
              filterData={filteredGraphDetails[1].values}
              isConversionPositive={
                data.totals.saved.isConversionPositive ?? undefined
              }
              hoveredItem={hoveredItem}
              setHoveredItem={setHoveredItem}
              isProCompany={isProCompany}
            />
            <Statistic
              title="Clicked 'Apply'"
              value={data.totals.clickedApply.total}
              benchmark={data.totals.clickedApply.benchmark ?? undefined}
              conversion={data.totals.clickedApply.conversion ?? undefined}
              filterData={filteredGraphDetails[2].values}
              isConversionPositive={
                data.totals.clickedApply.isConversionPositive ?? undefined
              }
              hoveredItem={hoveredItem}
              setHoveredItem={setHoveredItem}
              isProCompany={isProCompany}
            />
            <Statistic
              title="Applied"
              value={data.totals.applied.total ?? undefined}
              benchmark={data.totals.applied.benchmark ?? undefined}
              conversion={data.totals.applied.conversion ?? undefined}
              filterData={filteredGraphDetails[3].values}
              isConversionPositive={
                data.totals.applied.isConversionPositive ?? undefined
              }
              hoveredItem={hoveredItem}
              setHoveredItem={setHoveredItem}
              isProCompany={isProCompany}
              hasChevron={false}
            >
              <CandidatesModal totalCandidates={data.totals.applied.total} />
            </Statistic>
          </Row>

          <Row>
            <AverageTimeStatistic>
              <Statistic
                title="Average view time"
                unitDisplay={isDesktop ? " seconds" : " secs"}
                benchmark={data.viewTime.benchmark?.toString()}
                conversion={data.viewTime.averageTime?.toString()}
                isConversionPositive={
                  data.viewTime.isAverageTimePositive ?? undefined
                }
                column
                showConversionHeading={false}
                hasChevron={false}
              />
            </AverageTimeStatistic>
            <GraphWrapper>
              {currentFilter === "all" && (
                <BarGraph
                  bars={graphDetails}
                  graphTitle="Number of candidates"
                />
              )}

              {currentFilter !== "all" && (
                <StackedBarGraph
                  bars={filteredGraphDetails.slice(1)}
                  graphTitle="Number of candidates"
                  hoveredItem={hoveredItem}
                  setHoveredItem={setHoveredItem}
                />
              )}
            </GraphWrapper>
          </Row>
        </Container>
      )}
      {!hasData && <MissingDataPlaceholder />}
    </Spacing>
  );
}
