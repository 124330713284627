import styled from "@xstyled/styled-components";
import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useJobSlotStripeCheckoutLink } from "../useJobSlotStripeCheckoutLink";
import { JobSlotsModal } from "../JobSlotsModal";

import { Button, Heading, Text } from "@otta/design";
import { pxToRem } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import {
  CatalogueProductId,
  JobInfoDocument,
  JobInfoQuery,
  ProductQuoteDocument,
} from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { formatCurrency } from "@hire/util/currencies";
import { useCurrentUser } from "@hire/hooks/useCurrentUser";

const IconContainer = styled.div`
  display: inline;
  margin-right: sm;
`;

const ButtonContainer = styled.div`
  margin-top: xl;
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;

const LineItem = styled(Text)<{ hasMargin?: boolean }>`
  display: flex;
  margin: ${({ hasMargin }) => hasMargin && `lg 0`};
`;

const StyledButton = styled(Button)`
  width: 50%;
`;

export function Pricing() {
  const { jobExternalId } = useParams();
  const { data: jobData, loading: jobLoading } = useQuery(JobInfoDocument, {
    variables: { jobId: jobExternalId as string },
  });

  const [user, { loading: jobSlotsLoading }] = useCurrentUser();
  const company = user?.currentCompany;
  const paidSlots = company?.jobSlotData?.paid;
  const navigate = useNavigate();

  useEffect(() => {
    if ((paidSlots ?? 0) > 0) {
      navigate(`../unfreeze-your-role/${jobExternalId}`);
    }
  }, [navigate, paidSlots, jobExternalId]);

  if (!jobExternalId || jobLoading || !jobData?.companyJob || jobSlotsLoading) {
    return null;
  }

  return <PricingComponent job={jobData.companyJob} />;
}

const PricingComponent = ({
  job,
}: {
  job: NonNullable<JobInfoQuery["companyJob"]>;
}) => {
  const { data, loading } = useQuery(ProductQuoteDocument, {
    variables: {
      quantity: 1,
      productId: CatalogueProductId.JobSlotMonthly,
    },
  });

  const currency = data?.catalogue.product?.quote.currency;
  const subTotal = data?.catalogue.product?.quote.lineItems[0].netSubtotal;

  const { companySlug } = useParams<"companySlug">();
  const { callback, loading: stripeLoading } = useJobSlotStripeCheckoutLink(
    job.id,
    `${companySlug}/checkout/confirmation`
  );

  const formattedCurrency =
    subTotal && currency && formatCurrency(subTotal, currency);

  const checkpoints = [
    "Pay today to start hiring immediately.",
    "No commitment, cancel online anytime.",
    "Reuse this job slot for other jobs.",
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <JobSlotsModal name="pricing" redirectOnClose={`/${companySlug}/jobs`}>
      <Text size={2} bold>
        Publish your {job.title} role
      </Text>

      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          margin: `${pxToRem(24)} 0`,
        }}
      >
        <Heading size={3}>{formattedCurrency}</Heading>
        <Text bold size={1} style={{ marginLeft: pxToRem(8) }}>
          per month (excluding VAT)
        </Text>
      </div>

      {checkpoints.map((checkpoint, idx) => (
        <LineItem key={idx}>
          <IconContainer>
            <Icon icon="checkmark" size={1.5} />
          </IconContainer>
          {checkpoint}
        </LineItem>
      ))}

      <ButtonContainer>
        <StyledButton
          level="primary"
          disabled={stripeLoading}
          onClick={callback}
        >
          {stripeLoading ? "Loading" : "Continue to payment"}
        </StyledButton>
        <StyledButton
          level="destructive"
          as={Link}
          to="../why-otta"
          state={{ redirect: `../pricing/${job.externalId}` }}
        >
          Maybe later
        </StyledButton>
      </ButtonContainer>
    </JobSlotsModal>
  );
};
