import styled, { down, css } from "@xstyled/styled-components";
import { useQuery } from "@apollo/client";
import { useEffect, useMemo } from "react";

import { JobPreview, JobPreviewEmptySlot } from "./JobPreview";
import { JobsListEmptyState } from "./JobsListEmptyState";
import { JobListStatus } from "./statusTypes";

import { Spacing, Text, Tipbox } from "@otta/design";
import { palette } from "@otta/design-tokens";
import { Feature, PublishedJobsListDocument } from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { ReloadableError } from "@hire/components/ReloadableError";
import { hireAppUser } from "@hire/util/user";
import { useAuthorization } from "@hire/providers/authorization";

const Container = styled.div`
  border-bottom: 1px solid ${palette.grayscale.shade200};
  padding: 1.5rem;
`;

const JobList = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: lg;
  align-items: stretch;
`;

const Row = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;

  ${down(
    "tablet",
    css`
      flex-direction: column-reverse;
      align-items: flex-start;
      gap: 1rem;
    `
  )}
`;

export function PublishedJobList({
  onMissingSalary,
  actionButtons,
}: {
  onMissingSalary: () => void;
  actionButtons: React.ReactNode;
}) {
  const { data, error, loading, refetch } = useQuery(PublishedJobsListDocument);

  const user = hireAppUser(data?.me);
  const jobs = useMemo(() => user?.currentCompany?.listJobs ?? [], [user]);
  const createJobFeature = useAuthorization(Feature.CreateJob);
  const totalJobs = user?.currentCompany?.totalIncludingPaused;
  const hasAvailableSlots =
    (user?.currentCompany?.jobSlotData?.available ?? 0) > 0;

  useEffect(() => {
    if (
      jobs.some(j => !j.salaryRange && !j.oteSalaryRange) &&
      onMissingSalary
    ) {
      onMissingSalary();
    }
  }, [jobs, onMissingSalary]);

  return (
    <Container data-testid="published">
      <Spacing size={2}>
        <Row>
          <Text as="h3" bold size={2}>
            Published jobs ({jobs.length})
          </Text>
          {jobs.length > 0 && actionButtons}
        </Row>
        <Text>
          Job slots are filled automatically. You can swap them at any time.
        </Text>
        <JobList>
          {jobs.map(j => (
            <JobPreview key={j.id} {...j} status={JobListStatus.PUBLISHED} />
          ))}
          {!createJobFeature.loading && !!totalJobs && hasAvailableSlots && (
            <JobPreviewEmptySlot showCTA={createJobFeature.granted} />
          )}
          {loading && <Loading />}
          {!jobs.length &&
            !error &&
            !loading &&
            (totalJobs === 0 ? (
              <JobsListEmptyState />
            ) : (
              <Tipbox level="information">
                You don't have any published jobs.
              </Tipbox>
            ))}
          {error && !loading && <ReloadableError action={() => refetch()} />}
        </JobList>
      </Spacing>
    </Container>
  );
}
