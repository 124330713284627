import styled from "@xstyled/styled-components";
import { useMedia } from "react-use";
import { useQuery } from "@apollo/client";
import { format, parseJSON } from "date-fns";

import { InboundStatistics } from "./InboundStatistics";
import { SourcingStatistics } from "./SourcingStatistics";
import { MobilePlaceholder } from "./components/MobilePlaceholder";
import { Upsell } from "./components/Upsell";
import { CandidatePool } from "./CandidatePool";
import { Overview } from "./Overview";

import { Spacing, theme } from "@otta/design";
import { PageContentContainer } from "@hire/components/containers/PageContentContainer";
import { useRequiredParams } from "@hire/util/routing";
import { pxToEm, pxToRem } from "@otta/design-tokens";
import {
  JobFeature,
  JobPerformanceDocument,
  UpsellRelevantCandidatesDocument,
} from "@hire/schema";
import { useJobAuthorization } from "@hire/providers/authorization";
import { Loading } from "@otta/shared-components";
import { useCurrentUser } from "@hire/hooks/useCurrentUser";
import { FailureOverlay } from "@hire/components/FailureOverlay";

const PageContent = styled.div`
  max-width: ${pxToRem(1024)};
`;

export const Analytics = () => {
  const [user] = useCurrentUser();
  const { jobId } = useRequiredParams(["jobId"]);
  const { loading: isLoadingJobAuthorization, granted: hasSourcing } =
    useJobAuthorization(JobFeature.Sourcing);
  const {
    data,
    loading: isLoadingJobPerformance,
    error: jobPerformanceError,
  } = useQuery(JobPerformanceDocument, {
    variables: { id: jobId },
  });

  const isProCompany = !!user?.currentCompany?.isPro;

  const isLoading = isLoadingJobAuthorization || isLoadingJobPerformance;

  const { data: relCands } = useQuery(UpsellRelevantCandidatesDocument, {
    variables: { id: jobId },
  });

  const relevantCandidates =
    relCands?.companyJob?.personalisedCandidates?.total ?? null;

  const stats = data?.companyJob?.performanceStatistics;
  const isDesktop = useMedia(`(min-width: ${pxToEm(theme.screens.tablet)})`);

  if (isLoading) {
    return <Loading />;
  }

  if (jobPerformanceError) {
    return (
      <FailureOverlay>
        We couldn&apos;t load these stats right now &mdash; check back later
      </FailureOverlay>
    );
  }

  const firstPosted = data?.companyJob?.firstLiveAt
    ? format(parseJSON(data?.companyJob?.firstLiveAt), "d MMMM yyyy")
    : null;

  const lastRefreshed =
    stats?.refreshedAt &&
    format(parseJSON(stats.refreshedAt), "EEEE d MMMM 'at' HH:mm");

  const hasCandidatePoolData =
    stats?.candidatePool && stats.candidatePool.total > 0;

  return (
    <PageContentContainer>
      <PageContent>
        <Spacing size={2}>
          <Overview
            candidatePool={stats?.candidatePool.total}
            candidatesViewed={stats?.inboundPerformance.totals.viewed.total}
            candidateNotified={stats?.candidatePool.notifiedCandidates.total}
            candidateNotifiedLastWeek={
              stats?.candidatePool.notifiedCandidates.lastWeekTotal
            }
            sourcingPool={undefined}
            firstPosted={firstPosted}
            lastRefreshed={lastRefreshed}
          />
          {isDesktop && (
            <Spacing size={2}>
              {hasCandidatePoolData && (
                <CandidatePool
                  data={stats.candidatePool}
                  isProCompany={isProCompany}
                />
              )}
              <InboundStatistics
                data={stats?.inboundPerformance}
                isProCompany={isProCompany}
              />
              {isProCompany && hasSourcing && (
                <SourcingStatistics
                  data={stats?.sourcingPerformance}
                  relevantCandidates={relevantCandidates}
                />
              )}
              {!isProCompany && <Upsell />}
            </Spacing>
          )}
          {!isDesktop && <MobilePlaceholder />}
        </Spacing>
      </PageContent>
    </PageContentContainer>
  );
};
