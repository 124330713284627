import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useCallback } from "react";
import styled from "@xstyled/styled-components";

import { SourcingSideBar } from "../shared";

import { useSourcingPreferences } from "./useSourcingPreferences";
import { JobSubFunctionIdsField } from "./JobSubFunctionIdsField";
import { KeywordsField } from "./KeywordsField";
import { MaxSalaryField } from "./MaxSalaryField";
import { ExperienceRangeField } from "./ExperienceRangeField";
import { RegionsField } from "./RegionsField";
import { VisaSponsorshipRegionsField } from "./VisaSponsorshipRegionsField";
import { SavedJobAndCompanyFields } from "./SavedJobAndCompanyFields";
import { CandidateActivityField } from "./CandidateActivityField";
import { DiversityFields } from "./DiversityFields";
import { CompletedProfileField } from "./CompletedProfileField";
import { CompanyNamesField } from "./CompanyNamesField";
import { SubregionsField } from "./SubregionsField";

import {
  JobSubFunctionsDocument,
  PersonalisedCandidatesPreferences,
  WorkExperienceCompaniesDocument,
} from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { Spacing } from "@otta/design";

const Container = styled.div`
  padding-top: lg;
`;

export function SourcingPreferences(): React.ReactElement | null {
  const params = useParams<"jobId">();

  const jobId = params.jobId as string;

  const { preferences, setPreferences } = useSourcingPreferences(jobId, {
    fetchPolicy: "cache-only",
  });

  const { data: jobSubFunctionsData } = useQuery(JobSubFunctionsDocument, {
    variables: { jobId },
  });

  const { data: workExpCompaniesData } = useQuery(
    WorkExperienceCompaniesDocument
  );

  const handleSubmit = useCallback(
    (partialPreferences: Partial<PersonalisedCandidatesPreferences>) => {
      setPreferences({ ...preferences, ...partialPreferences });
    },
    [preferences, setPreferences]
  );

  return (
    <SourcingSideBar>
      <Container>
        {!preferences ? (
          <Loading />
        ) : (
          <Spacing size={1}>
            <JobSubFunctionIdsField
              preferences={preferences}
              optionsData={jobSubFunctionsData}
              onChange={handleSubmit}
            />
            <KeywordsField preferences={preferences} onChange={handleSubmit} />
            <ExperienceRangeField
              preferences={preferences}
              onChange={handleSubmit}
            />
            <MaxSalaryField preferences={preferences} onChange={handleSubmit} />
            <RegionsField preferences={preferences} onChange={handleSubmit} />
            <SubregionsField
              preferences={preferences}
              onChange={handleSubmit}
            />
            <VisaSponsorshipRegionsField
              preferences={preferences}
              onChange={handleSubmit}
            />
            <CandidateActivityField
              preferences={preferences}
              onChange={handleSubmit}
            />
            <SavedJobAndCompanyFields
              preferences={preferences}
              onChange={handleSubmit}
            />
            <DiversityFields
              preferences={preferences}
              onChange={handleSubmit}
            />
            <CompletedProfileField
              preferences={preferences}
              onChange={handleSubmit}
            />
            <CompanyNamesField
              preferences={preferences}
              optionsData={workExpCompaniesData}
              onChange={handleSubmit}
            />
          </Spacing>
        )}
      </Container>
    </SourcingSideBar>
  );
}
