import { Link } from "react-router-dom";
import { useMemo } from "react";

import { Button, Spacing, Text } from "@otta/design";
import { useAuthorizations } from "@hire/providers/authorization";
import { Feature } from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { useCurrentUser } from "@hire/hooks/useCurrentUser";

const COPY = (hasJobSlotsAccess: boolean, paidSlots?: number) => ({
  hasAts: {
    text: (
      <Text style={{ lineHeight: 1.5 }}>
        You can use an ATS integration to ensure that all of your published jobs
        appear. <br /> When you post jobs on your ATS they will appear here.
      </Text>
    ),
    buttonCopy: "Go to ATS Integration",
    link: "../settings/ats",
  },
  postsDirectly: {
    text: (
      <Text>
        Once you&apos;ve created a job we&apos;ll review and approve it.
      </Text>
    ),
    buttonCopy: "Create a job",
    link: hasJobSlotsAccess && paidSlots === 0 ? "./add-a-job" : "./create",
  },
});

export const JobsListEmptyState = () => {
  const [user, { loading }] = useCurrentUser();

  const { features, loading: featuresLoading } = useAuthorizations([
    Feature.CreateJob,
    Feature.JobSlots,
  ]);

  const availableSlots = useMemo(() => {
    return user?.currentCompany?.jobSlotData?.available ?? 0;
  }, [user]);

  const createJobsFeature = features.get(Feature.CreateJob);
  const jobSlotsFeature = features.get(Feature.JobSlots);

  if (featuresLoading || loading) {
    return <Loading />;
  }

  const templateCopy = createJobsFeature?.granted
    ? COPY(jobSlotsFeature?.granted ?? false, availableSlots).postsDirectly
    : COPY(false, availableSlots).hasAts;

  return (
    <div data-testid="empty-state-wrapper">
      <Spacing size={3}>
        {templateCopy.text}
        <Button
          level="primary"
          as={Link}
          to={templateCopy.link}
          relative="path"
        >
          {templateCopy.buttonCopy}
        </Button>
      </Spacing>
    </div>
  );
};
