import { SourcingPreferencesFieldProps } from "./interfaces";

import { VerticalSpacing, Label, Text, Spacing } from "@otta/design";
import { Radio } from "@hire/components/input/Radio";
import { LastActive } from "@hire/schema";

export function CandidateActivityField({
  onChange,
  preferences,
}: SourcingPreferencesFieldProps): React.ReactElement {
  return (
    <VerticalSpacing size={-4}>
      <Label>
        <Text bold>Candidate activity</Text>
      </Label>
      <Spacing size={-2}>
        <Radio
          size={-1}
          label="Last 12 weeks"
          name="activity-period-radio"
          checked={preferences.lastActive === LastActive.TwelveWeeksAgo}
          onChange={() => {
            onChange({ lastActive: LastActive.TwelveWeeksAgo });
          }}
        />
        <Radio
          size={-1}
          label="Last 6 weeks"
          name="activity-period-radio"
          checked={preferences.lastActive === LastActive.SixWeeksAgo}
          onChange={() => {
            onChange({ lastActive: LastActive.SixWeeksAgo });
          }}
        />
        <Radio
          size={-1}
          label="Last 7 days"
          name="activity-period-radio"
          checked={preferences.lastActive === LastActive.SevenDaysAgo}
          onChange={() => {
            onChange({ lastActive: LastActive.SevenDaysAgo });
          }}
        />
      </Spacing>
    </VerticalSpacing>
  );
}
